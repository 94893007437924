
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutOS7HpsGFDFSTbbd4iXDP92Iuvckam0GQw5dgZEXCgBIMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as resumeHX6aPRYJDnBeEaUi_3V6aonnZl0XS28ty619uVg2PcIMeta } from "/vercel/path0/pages/resume.vue?macro=true";
import { default as contact6cZoOGxJBTY9l_45DSf37OWQKprIBU1PffJx7PVn14dCsMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as profileFId_45d62DZvDfSRqu_45gOkgT1Y2dyc04X7CB8OOqCLklMMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as _4045pIaTsNrUpJq3uCKB_45lD_45yYPGPD_45v880C_TkrwdIW6oMeta } from "/vercel/path0/pages/blog/404.vue?macro=true";
import { default as newMlEONh0SG72qKU5DEjfIZy7OsmHrcrYah1Iy7ry6zE0Meta } from "/vercel/path0/pages/blog/new.vue?macro=true";
import { default as terminalkv0C8IgnzirtOZevC81SdTFV4b94I_45A2ajOWokzrIdwMeta } from "/vercel/path0/pages/terminal.vue?macro=true";
import { default as indexDIlA_VwVYEsF82JYYTptIRBexsOVSbsbgl1xBQ_lIIoMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as callbackh5BlNk_5NmpfVDD4b6dX2d7K12NPXA5wsmB_CKYJNNsMeta } from "/vercel/path0/pages/auth/callback.vue?macro=true";
import { default as _91_46_46_46slug_93yjILy3ZXIfpwFFfFDGoowZQbfYO4NCBblrfqY9lzQT0Meta } from "/vercel/path0/pages/blog/[...slug].vue?macro=true";
import { default as _91slug_93fmZQIdsG6qmqh0lANyZNXZGB8o5B6kIsaU8BgkjXJOcMeta } from "/vercel/path0/pages/blog/edit/[slug].vue?macro=true";
import { default as verifyQcG8Oo0CoT9E4nT01NyZLa2f3987kewUfFPZU_zDMPIMeta } from "/vercel/path0/pages/newsletter/verify.vue?macro=true";
import { default as ai_45questionsCQ_yduz2tCpbR0iLV7oKf0NUCSzSH0bPPQYiXTKUj1sMeta } from "/vercel/path0/pages/admin/ai-questions.vue?macro=true";
import { default as indexy073qiW67yxYvTo8P17X4s_454kJWxWwqm__fUp_45Ccc_45YMeta } from "/vercel/path0/pages/admin/newsletter/index.vue?macro=true";
import { default as unsubscribezcPAsEorQAtqOgwWbU6Zm9Oq25a0ZLrO0QLdceVXxO0Meta } from "/vercel/path0/pages/newsletter/unsubscribe.vue?macro=true";
import { default as _91id_930N_45mOGB_8mFdjzsI_Li2Ufp7fwzI1n41TSZ7hKGJ444Meta } from "/vercel/path0/pages/admin/newsletter/edit/[id].vue?macro=true";
import { default as _91id_93O1uT37_yoDVfO8RDy6pWIwPRuPQ2Y_45JHQbYrbJvGdZ0Meta } from "/vercel/path0/pages/admin/newsletter/preview/[id].vue?macro=true";
import { default as _91id_939kaKFSx364X4QS9vV0GkFwCySPGl2K8wdhXNIhBRgq8Meta } from "/vercel/path0/pages/admin/newsletter/analytics/[id].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "resume",
    path: "/resume",
    component: () => import("/vercel/path0/pages/resume.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/vercel/path0/pages/profile.vue")
  },
  {
    name: "blog-404",
    path: "/blog/404",
    component: () => import("/vercel/path0/pages/blog/404.vue")
  },
  {
    name: "blog-new",
    path: "/blog/new",
    meta: newMlEONh0SG72qKU5DEjfIZy7OsmHrcrYah1Iy7ry6zE0Meta || {},
    component: () => import("/vercel/path0/pages/blog/new.vue")
  },
  {
    name: "terminal",
    path: "/terminal",
    component: () => import("/vercel/path0/pages/terminal.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/vercel/path0/pages/auth/callback.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blog/[...slug].vue")
  },
  {
    name: "blog-edit-slug",
    path: "/blog/edit/:slug()",
    meta: _91slug_93fmZQIdsG6qmqh0lANyZNXZGB8o5B6kIsaU8BgkjXJOcMeta || {},
    component: () => import("/vercel/path0/pages/blog/edit/[slug].vue")
  },
  {
    name: "newsletter-verify",
    path: "/newsletter/verify",
    component: () => import("/vercel/path0/pages/newsletter/verify.vue")
  },
  {
    name: "admin-ai-questions",
    path: "/admin/ai-questions",
    component: () => import("/vercel/path0/pages/admin/ai-questions.vue")
  },
  {
    name: "admin-newsletter",
    path: "/admin/newsletter",
    meta: indexy073qiW67yxYvTo8P17X4s_454kJWxWwqm__fUp_45Ccc_45YMeta || {},
    component: () => import("/vercel/path0/pages/admin/newsletter/index.vue")
  },
  {
    name: "newsletter-unsubscribe",
    path: "/newsletter/unsubscribe",
    component: () => import("/vercel/path0/pages/newsletter/unsubscribe.vue")
  },
  {
    name: "admin-newsletter-edit-id",
    path: "/admin/newsletter/edit/:id()",
    meta: _91id_930N_45mOGB_8mFdjzsI_Li2Ufp7fwzI1n41TSZ7hKGJ444Meta || {},
    component: () => import("/vercel/path0/pages/admin/newsletter/edit/[id].vue")
  },
  {
    name: "admin-newsletter-preview-id",
    path: "/admin/newsletter/preview/:id()",
    meta: _91id_93O1uT37_yoDVfO8RDy6pWIwPRuPQ2Y_45JHQbYrbJvGdZ0Meta || {},
    component: () => import("/vercel/path0/pages/admin/newsletter/preview/[id].vue")
  },
  {
    name: "admin-newsletter-analytics-id",
    path: "/admin/newsletter/analytics/:id()",
    meta: _91id_939kaKFSx364X4QS9vV0GkFwCySPGl2K8wdhXNIhBRgq8Meta || {},
    component: () => import("/vercel/path0/pages/admin/newsletter/analytics/[id].vue")
  }
]